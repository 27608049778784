@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}


@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/OTF_Font_Proxima_Nova/Proxima-Nova-Reg.otf") format("otf");
}


/* CUSTOM STYLES TO TRANSLATE INTO TAIL-WIND CLASSES */
.tooltips {
  visibility: hidden;
  position:absolute;
  
}

.has-tooltip:hover .tooltips {
  visibility:visible;
  z-index: 90;
}
/* width */
.main::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .hot::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .main::-webkit-scrollbar-track {
    margin-top: 100px;
    margin-bottom: 20px;
    //background: #303233; 
    background: transparent;
  }
  

  ::-webkit-scrollbar-track {
    margin-top: 20px;
    margin-bottom: 20px;
    //background: #303233; 
    background: transparent;
  }
  .hot::-webkit-scrollbar-track {
    background: transparent;
    //background: #303233; 
    opacity: 0;
  }

  .DWC::-webkit-scrollbar-thumb {
    background: #FF1AE8; 
  }
  
  /* Handle on hover */
  .DWC::-webkit-scrollbar-thumb:hover {
    background: #FF1AE8; 
  }

  .market::-webkit-scrollbar-thumb {
    background: #00aeef; 
  }
  
  /* Handle on hover */
  .market::-webkit-scrollbar-thumb:hover {
    background: #00aeef; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FF0000; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FF0000; 
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.loadcontainer{
    position: absolute;
    z-index: 10;
    top:50vh;
    left:100vh;
  }
  .loadingspinner {
    width: 200px;
    height: 200px;
    border: 15px solid #F5F5E4; /* Light grey */
    border-top: 15px solid #000; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .toast{
    opacity: 1;
  }

  @keyframes bounce {
    0% { top: 300px; }
    100% { top: 0; }
  }
  
  #redball {
    animation: bounce 2s infinite alternate;
  }

  .web3modal-modal-lightbox { z-index: 9999 !important; }

  @-ms-viewport{ width: device-width; }